"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
function reducer(state, action) {
    switch (action.type) {
        case 'advanceTime': {
            var timeToAdd = action.payload.timeToAdd;
            return __assign(__assign({}, state), { time: state.timerType === 'DECREMENTAL'
                    ? state.time - timeToAdd
                    : state.time + timeToAdd });
        }
        case 'pause': {
            return __assign(__assign({}, state), { status: 'PAUSED' });
        }
        case 'reset': {
            return __assign(__assign({}, state), { status: 'STOPPED', time: action.payload.initialTime });
        }
        case 'set': {
            return __assign(__assign({}, state), { time: action.payload.newTime });
        }
        case 'start': {
            var initialTime = action.payload.initialTime;
            return __assign(__assign({}, state), { status: 'RUNNING', time: state.status === 'STOPPED' ? initialTime : state.time });
        }
        case 'stop': {
            return __assign(__assign({}, state), { status: 'STOPPED' });
        }
        default:
            return state;
    }
}
exports.default = reducer;
